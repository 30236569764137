import { RouteObject } from "react-router-dom";

import { HomePage } from "./pages/HomePage";

import App from "./App";

export const routes: RouteObject[] = [
	{
		element: <App />,
		children: [
			{
				path: "/:handle?",
				element: <HomePage />,
			},
		],
	},
];
