import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface TrackProps {
	hostname?: string;
	language?: string;
	referrer?: string;
	screen?: string;
	title?: string;
	url?: string;
	website?: string;
}

function ingoreErrors<R>(fn: () => R) {
	try {
		return fn();
	} catch (e) {
		//ignore
	}
}

function parseReferrer(value: string) {
	return [value, `https://${value}`].find((value) => URL.canParse(value)) || "";
}

export interface AnalyticsContextValue {
	pageView: (data?: TrackProps) => void;
	event: (event: string, data: Record<string, any>) => void;
}

export const AnalyticsContext = createContext<
	AnalyticsContextValue | undefined
>(undefined);

export interface AnalyticsProviderProps {
	referrerQueryParams?: string | RegExp[];
	removeQueryParams?: string | RegExp[];
}

export const DEFAULT_REFERRER_QUERY_PARAMS = ["ref", "utm_source"];
export const DEFAULT_REMOVE_QUERY_PARAMS = ["ref", /utm_.*/];

function buildUrl(path: string, qs: string) {
	const url = new URL(path, window.location.href);

	for (const entry of new URLSearchParams(qs).entries()) {
		url.searchParams.append(entry[0], entry[1]);
	}

	return url.pathname + url.search + url.hash;
}

export const AnalyticsProvider = (
	props: PropsWithChildren<AnalyticsProviderProps>,
) => {
	const {
		referrerQueryParams = DEFAULT_REFERRER_QUERY_PARAMS,
		removeQueryParams = DEFAULT_REMOVE_QUERY_PARAMS,
		children,
	} = props;

	const location = useLocation();
	const navigate = useNavigate();

	const [initialized, setInitialized] = useState<boolean>(false);

	const pageView = useCallback((props: TrackProps = {}) => {
		ingoreErrors(() =>
			umami.track((umamiProps) => ({
				...umamiProps,
				title: document.title,
				...props,
			})),
		);
	}, []);

	const event = useCallback((event: string, data: Record<string, any>) => {
		ingoreErrors(() => umami.track(event, data));
	}, []);

	// track the first page view and deduct the referrer from query params if not set
	useEffect(() => {
		console.log("initialization", location);

		let referrer = "";
		const qs = new URLSearchParams(location.search);

		if (typeof document !== "undefined" && document.referrer) {
			referrer = new URL(document.referrer).host;
		} else {
			for (const param of referrerQueryParams) {
				const matchedEntries = Array.from(qs.entries()).filter((entry) =>
					param instanceof RegExp ? entry[0].match(param) : entry[0] === param,
				);

				for (const entry of matchedEntries) {
					const parsedReferrer = parseReferrer(entry[1]);
					if (parsedReferrer) {
						referrer = parsedReferrer;
						break;
					}
				}

				if (referrer) {
					break;
				}
			}
		}

		for (const param of removeQueryParams) {
			const matchedKeys = Array.from(qs.keys()).filter((key) =>
				param instanceof RegExp ? key.match(param) : key === param,
			);

			for (const key of matchedKeys) {
				qs.delete(key);
			}
		}

		console.log("initial page view");

		pageView({
			referrer,
		});

		navigate(
			{
				...location,
				search: qs.toString(),
			},
			{
				replace: true,
				state: {
					$type: "ANALYTICS_CONTEXT_INIT",
				},
			},
		);
	}, []);

	// track every page view except the first one (tracked during initialization)
	useEffect(() => {
		console.log("on location", location);
		const locationState = location.state || {};

		if (
			"$type" in locationState &&
			locationState.$type === "ANALYTICS_CONTEXT_INIT"
		) {
			setInitialized(true);
			return;
		}

		if (initialized) {
			console.log("page view", location);

			pageView({
				url: location.pathname + location.search + location.hash,
			});
		}
	}, [location]);

	if (initialized === undefined) {
		return null;
	}

	console.log("initialized", initialized);

	return (
		<AnalyticsContext.Provider value={{ pageView, event }}>
			{children}
		</AnalyticsContext.Provider>
	);
};

export function useAnalytics() {
	const context = useContext(AnalyticsContext);

	if (!context) {
		throw new Error(
			"In order to use AnalyticsContext wrap the components with AnalyticsProvider",
		);
	}

	return context;
}
