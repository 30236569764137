import { Outlet, ScrollRestoration } from "react-router-dom";

import { AnalyticsProvider } from "./context/AnalyticsContext";

function App() {
	return (
		<AnalyticsProvider>
			<Outlet />
			<ScrollRestoration />
		</AnalyticsProvider>
	);
}

export default App;
