import clsx from "clsx";
import { Sparkles } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import HabitFlixLogo from "@/assets/habitflix-logo-dark.svg";

import { Badge } from "./ui/badge";

interface Banner {
	isAd: boolean;
	link: string;
	content: ReactNode;
	alwaysShowLearnMore?: boolean;
}

const allBanners: Banner[] = [
	{
		isAd: false,
		link: "https://notemation.gumroad.com/l/fjxamz",
		content: (
			<>
				<Sparkles
					size={16}
					className="me-1.5 ms-3 stroke-yellow-600 shrink-0"
				/>
				<span className="mx-2">
					<span className="font-semibold">Advertise</span>{" "}
					<span className="">your product in this banner.</span>{" "}
					<span className="bg-yellow-200 text-yellow-800 font-medium rounded-sm px-1 py-[1px] text-[12px] whitespace-nowrap hidden">
						$19/week only
					</span>
				</span>
			</>
		),
		alwaysShowLearnMore: true,
	},
	{
		isAd: true,
		link: "https://habitflix.com?utm_source=besttimetopost.blue&utm_content=banner",
		content: (
			<div className="mx-2 flex-row items-center">
				<img
					src={HabitFlixLogo}
					className="size-[16px] inline-block relative top-[-1px] me-2"
					alt="HabitFlix logo"
				/>
				<span>
					<span className="mr-2">
						<span className="font-semibold">HabitFlix</span>{" "}
						<span className="">- Notion Habit Tracker for 2025</span>
					</span>{" "}
				</span>
			</div>
		),
	},
];

export const AdBanner = () => {
	const [banner, setBanner] = useState<Banner>();

	const location = useLocation();

	useEffect(() => {
		setBanner(allBanners[Math.floor(Math.random() * allBanners.length)]!);
	}, [location]);

	return (
		<Link
			to={banner?.link || "#"}
			target="_blank"
			rel="noreferrer"
			className="border-b bg-white px-2 py-2.5 text-[13px] flex flex-row items-center leading-[20px] -mx-4 sm:mx-0 sm:border-x sm:rounded-b-md mb-6 sm:min-h-[47px] min-h-[58px]"
		>
			{banner?.isAd && (
				<Badge
					variant="outline"
					className="text-[11px] px-1.5 font-medium py-[1px] mx-2"
				>
					Ad
				</Badge>
			)}
			{banner?.content}
			<Badge
				variant="outline"
				className={clsx(
					"sm:block mx-2 ms-auto py-1 px-2 h-auto shadow-none font-medium whitespace-nowrap",
					banner?.alwaysShowLearnMore ? "block" : "hidden",
				)}
			>
				Learn more
			</Badge>
		</Link>
	);
};
